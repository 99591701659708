import { createEffect, createSignal } from 'solid-js';
import { MOVE_IN_DATE } from '~/assets/strings';
import { DatePicker } from '~/components/ui';
import { useLocalization } from '~/hooks/useLocalization';
import { SearchBase } from './SearchBase';

interface SearchMoveInDateProps {
  moveInDate: string;
  onChange: (value: any) => void;
  valueDisplay?: string;
  id?: string;
}

export const SearchMoveInDate = (props: SearchMoveInDateProps) => {
  const { t } = useLocalization();
  const [moveInDate, setMoveInDate] = createSignal<any>();

  const onApply = () => {
    props.onChange?.(moveInDate());
  };

  const onOutsideClick = () => {
    setMoveInDate(props.moveInDate);
  };

  createEffect(() => {
    setMoveInDate(props.moveInDate);
  });

  return (
    <>
      <div class="hidden lg:block">
        <SearchBase title={props.valueDisplay || t(MOVE_IN_DATE)} onApply={onApply} onOutsideClick={onOutsideClick}>
          <div class="h-9 bg-[#F5F5F9] px-6 text-xs leading-9 text-[#6B738A] ">{t(MOVE_IN_DATE)}</div>
          <div class="flex flex-col gap-[22px]  bg-white p-6 ">
            <DatePicker id={props.id} value={moveInDate()} onChange={setMoveInDate} />
          </div>
        </SearchBase>
      </div>
      <div class="block lg:hidden">
        <div class="h-9 text-base font-medium leading-9 text-title-gray">{t(MOVE_IN_DATE)}</div>
        <div class="flex flex-col bg-white">
          <DatePicker
            id={props.id}
            value={moveInDate()}
            onChange={(value) => {
              setMoveInDate(value);
              onApply();
            }}
          />
        </div>
      </div>
    </>
  );
};
